import Head from 'next/head';

const Meta = ({
	title,
	keyword,
	desc,
}) => {
	return (
		<Head>
			<title>{`${title} | TevkilApp`}</title>

			<link rel="icon" href="/new64awhite.png"
					media="(prefers-color-scheme: dark)"/>
			<link rel="icon" href="/new64color.png"
					media="(prefers-color-scheme: light)"/>


			<meta name="description" content={desc}/>
			<meta name="keyword" content={keyword}/>
		</Head>
	);
};

Meta.defaultProps = {
	title: 'TevkilApp - Avukat Tevkil Platformu ',
	keyword:
		'tevkil, avukat tevkil, istanbul tevkil, ankara tevkil, izmir tevkil, tevkilat, tevkil oluştur, tevkil ara, tevkilapp, tevkil uygulama',
	desc: 'TevkilApp, CMK benzeri sıralama algoritması ile adil,hızlı ve güvenilir bir şekilde tevkil görevi verip alabileceğiniz avukat tevkil platformu.',
};

export default Meta;
