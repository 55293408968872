import Image from 'next/image';
import Link from 'next/link';
import {useTheme} from 'next-themes';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

const Hero_6 = () => {
	const {theme, setTheme} = useTheme();
	const user = useSelector((state) => state.user.user);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (user) {
			setLoading(false);
		}
	}, [user]);

	return (
		<>
			{/* <!-- Hero --> */}
			<section className="relative pb-32 pt-20 lg:pt-48">
				<picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden ">
					<Image
						width={1519}
						height={773}
						priority
						src="https://cdn.tevkilapp.com/images/gradient.jpg"
						alt="gradient"
						className="h-full w-full object-cover"
					/>
				</picture>
				<picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
					<Image
						width={1519}
						height={773}
						priority
						className="h-full w-full"
						src="https://cdn.tevkilapp.com/images/gradient_dark.jpg"
						alt="gradient dark"
					/>
				</picture>
				<Image
					width={613}
					height={415}
					src="https://cdn.tevkilapp.com/images/patterns/pattern_donut.png"
					alt="pattern donut"
					className="absolute right-0 top-0 -z-10"
				/>

				<div className="ml-auto mr-auto h-full max-w-[91rem] px-4 ">
					<div className="grid h-full items-center gap-4 lg:grid-cols-12">
						<div className="sm:col-span-5 col-span-12 flex h-full flex-col items-center justify-center py-10 lg:items-start lg:py-20">
							<p className="mb-10 text-xs font-bold uppercase text-jacarta-500 dark:text-jacarta-200">
								TevkilApp.com&apos;u keşfedin
							</p>
							<h1 className="mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white lg:text-left lg:text-6xl">
								{/*Türkiye&apos;de İlk ve Tek!*/}
								CMK Benzeri Sıralama Sistemi
							</h1>
							<p className="mb-8 max-w-md text-center text-lg dark:text-jacarta-200 lg:text-left">
								CMK benzeri sıralama algoritması ile çalışan, hak kaybı yaşamadan avukatların birbirine tevkil görevi verip alabileceği tevkil platformu TevkilApp&apos;e hoş geldiniz.
							</p>
							<div className="flex sm:space-x-4 sm:flex-row flex-col items-center w-full sm:gap-0 sm:items-start sm:justify-normal gap-4 justify-center">
								{!user ? (
									<Link
										href="/uyelik/kayit"
										className="rounded-full bg-accent py-3 px-8 text-center sm:w-fit w-full font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
									>
										Hemen Kayıt Ol
									</Link>
								) : (<Link
									href="/gorevler/liste"
									className="rounded-full bg-accent py-3 px-8 text-center sm:w-fit w-full font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
								>
									Aktif Görevler
								</Link>)
								}
								<Link
									href="/tevkilapp-nasil-calisir"
									className="rounded-full bg-white py-3 px-8 text-center sm:w-fit w-full font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
								>
									Detaylı Bilgi
								</Link>
							</div>
						</div>

						{/* <!-- Hero image --> */}
						<div className="sm:col-span-6 col-span-12">
							<div className="relative text-center lg:pl-32 lg:text-right">
								<div className="absolute left-[5%] top-1/3 inline-block animate-fly rounded-2lg bg-[#EAF7E1] p-6 shadow-2xl sm:left-[15%] md:left-20">
									<div className="flex gap-4">
                    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-white">
                      <svg
								 xmlns="http://www.w3.org/2000/svg"
								 viewBox="0 0 24 24"
								 width="24"
								 height="24"
								 className="fill-green"
							 >
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm11.793 6.793L13 8h5v5l-1.793-1.793-3.864 3.864-2.121-2.121-2.829 2.828-1.414-1.414 4.243-4.243 2.121 2.122 2.45-2.45z"/>
                      </svg>
                    </span>
										<div className="text-left">
                      <span className="block font-display text-3xl text-green">
                        3x
                      </span>
											<span className="block font-display text-sm text-jacarta-600">
                        İşlerinizi Hızlandırın
                      </span>
										</div>
									</div>
								</div>

								{!loading &&
								theme === 'dark' ? (
									<Image
										width={524}
										height={670}
										src="https://cdn.tevkilapp.com/images/tevkilapp-banner-dark-1.png"
										alt="crypto consultant hero"
										className="inline-block rounded-2.5xl"
									/>
								) : (
									<Image
										width={524}
										height={670}
										src="https://cdn.tevkilapp.com/images/tevkilapp-banner-1.png"
										alt="crypto consultant hero"
										className="inline-block rounded-2.5xl"
									/>
								)
								}

								<div className="absolute bottom-0 right-[5%] inline-block animate-fly rounded-2lg bg-white p-8 shadow-2xl sm:bottom-28 sm:right-[5%] lg:-right-[17%]">
									<div className="text-left">
										<span className="block font-display text-3xl text-[#737EF2]">
                      +20bin
                    </span>
										<span className="mb-5 block font-display text-sm text-jacarta-600">
                      Hedeflenen avukat
                    </span>
										<Image
											width={152}
											height={24}
											src="https://cdn.tevkilapp.com/images/crypto-consultant/happy_customers.png"
											alt="happy customers"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- end hero --> */}
		</>
	);
};

export default Hero_6;
